import Scroll from '../helpers/scroll';
import Search from '../components/search/Search';
import { pixelBreakpoints } from '../helpers';
import scrollLock from 'scroll-lock';

const selectors = {
  mobileMenuOpen: '[data-mobile-menu-trigger]',
  mobileMenuClose: '[data-navigation-close]',
  mobileMenuOverlay: '[data-navigation-overlay]',
  mobileMenuFooter: '[data-mobile-menu-footer]',
  navigationWrapper: '[data-navigation-wrapper]',
  mainHeader: '[data-main-header]',
  megaNav: '[data-meganav]',
  megaNavOverlay: '[data-meganav-overlay]',
  navList: '[data-nav-list]',
  megaContent: '[data-mega-content]',
  megaSubTrigger: '[data-megamenu-sub-trigger]',
  megaSubContent: '[data-megamenu-sub-content]',
  megaBack: '[data-megamenu-back]',
  megaSubBack: '[data-megamenu-sub-back]',
  megaOverflowContainer: '[data-megamenu-overflow-container]',
};

const cssClasses = {
  hidden: 'hidden',
  transitionedIn: 'translate-x-full',
  transitionedOut: 'translate-x-0',
  slideInUp: 'translate-y-0',
  slideOutDown: 'translate-y-full',
  navActive: 'main-nav-item--active',
};

export default class MainHeader {
  constructor(section) {
    this.section = section;
    this.isDesktop = window.matchMedia(
      `(min-width: ${pixelBreakpoints.lg})`,
    ).matches;
    this.isMobile = window.matchMedia(
      `(max-width: ${pixelBreakpoints.lg})`,
    ).matches;

    const { settings } = section.data;

    if (settings.enable_fixed_header) {
      // this.listener = new Scroll(this.onScroll, this.section.el);
      this.section.el.className +=
        ' sticky top-0 z-30 bg-white transition-transform';
    }

    new Search(section);

    this._initMegaNav();
    this._initMobileNav();
  }

  onScroll = direction => {
    if (direction === 'up') {
      this.showMenu();
    } else if (direction === 'down') {
      this.hideMenu();
    }
  };

  showMenu = () => {
    this.section.el.classList.remove('-translate-y-full');
  };

  hideMenu = () => {
    this.section.el.classList.add('-translate-y-full');
  };

  _initMobileNav = () => {
    this.navList = this.section.el.querySelector(selectors.navList);
    this.navigationWrapper = this.section.el.querySelector(
      selectors.navigationWrapper,
    );
    const mobileMenuOpen = this.section.el.querySelector(
      selectors.mobileMenuOpen,
    );
    if (mobileMenuOpen) {
      mobileMenuOpen.addEventListener('click', this._openMobileNav);
    }

    this.mobileMenuClose = this.section.el.querySelector(
      selectors.mobileMenuClose,
    );
    if (this.mobileMenuClose) {
      this.mobileMenuClose.addEventListener('click', this._closeMobileNav);
    }

    this.mobileMenuOverlay = this.section.el.querySelector(
      selectors.mobileMenuOverlay,
    );

    if (this.mobileMenuOverlay) {
      this.mobileMenuOverlay.addEventListener('click', this._closeMobileNav);
    }

    this.mobileMenuFooter = this.section.el.querySelector(
      selectors.mobileMenuFooter,
    );
  };

  _openMobileNav = () => {
    this.mobileMenuOverlay.classList.add('flex');
    this.mobileMenuOverlay.classList.remove(cssClasses.hidden);
    this.navigationWrapper.classList.remove(cssClasses.transitionedOut);
    this.navigationWrapper.classList.add(cssClasses.transitionedIn);
    this.mobileMenuFooter.classList.remove(cssClasses.slideOutDown);
    this.mobileMenuFooter.classList.add(cssClasses.slideInUp);
    scrollLock.disablePageScroll();
  };

  _closeMobileNav = () => {
    this.mobileMenuOverlay.classList.remove('flex');
    this.mobileMenuOverlay.classList.add(cssClasses.hidden);
    this.navigationWrapper.classList.remove(cssClasses.transitionedIn);
    this.navigationWrapper.classList.add(cssClasses.transitionedOut);
    this.mobileMenuFooter.classList.remove(cssClasses.slideInUp);
    this.mobileMenuFooter.classList.add(cssClasses.slideOutDown);
    scrollLock.enablePageScroll();
    this._closeAllMegaNavs();
  };

  _initMegaNav = () => {
    this.announcementBar = document.querySelector(selectors.announcementBar);
    this.mainHeader = this.section.el.querySelector(selectors.mainHeader);
    if (this.announcementBar) {
      this.announcementBar.addEventListener(
        'mouseover',
        this._closeAllMegaNavs,
      );
    }

    this.mainHeader.addEventListener('mouseover', this._closeAllMegaNavs);
    this.megaNavs = this.section.el.querySelectorAll(selectors.megaNav);
    this.megaNavs.forEach(nav => {
      let mouseEvent;
      if (this.isDesktop) {
        mouseEvent = 'mouseover';
      } else {
        mouseEvent = 'click';
      }
      nav.addEventListener(mouseEvent, e => {
        e.preventDefault();
        const searchActive = document.body.classList.contains('search-active');

        if (!e.target.classList.contains(cssClasses.navActive)) {
          const openedNav = this._closeAllMegaNavs();
          const clickedNav = e.target.getAttribute('data-meganav');

          if (clickedNav !== openedNav && !searchActive) {
            this._openMegaNav(clickedNav);
            e.target.classList.add(cssClasses.navActive);
          }
        }
      });
    });

    const megaNavOverlay = this.section.el.querySelectorAll(
      selectors.megaNavOverlay,
    );
    megaNavOverlay.forEach(overlay => {
      let mouseEvent;
      if (this.isDesktop) {
        mouseEvent = 'mouseover';
      } else {
        mouseEvent = 'click';
      }
      overlay.addEventListener(mouseEvent, () => {
        this._closeAllMegaNavs();
      });
    });

    const megaBack = this.section.el.querySelectorAll(selectors.megaBack);
    megaBack.forEach(back => {
      back.addEventListener('click', () => {
        if (this.isMobile) {
          this._closeAllMegaNavs();
        }
      });
    });
  };

  _openMegaNav = navItem => {
    const selectedNav = document.getElementById(`${navItem}-meganav`);
    selectedNav.classList.remove(cssClasses.hidden);
    if (this.isDesktop) {
      const headerHeight = this.section.el.offsetHeight;
      let totalHeaderHeight = headerHeight;
      if (this.announcementBar) {
        totalHeaderHeight += this.announcementBar.offsetHeight;
      }
      const overflowContainer = selectedNav.querySelector(
        selectors.megaOverflowContainer,
      );
      overflowContainer.style.maxHeight = `calc(96vh - ${totalHeaderHeight}px)`;

      this.clickOffFunc = e => {
        if (!selectedNav.contains(e.target)) {
          this._closeAllMegaNavs();
        }
      };

      // Close nav if clicking anywhere on header
      this.section.el.addEventListener('click', this.clickOffFunc);
      scrollLock.disablePageScroll();
    }
  };

  _closeAllMegaNavs = () => {
    if (this.isDesktop) {
      this.section.el.removeEventListener('click', this.clickOffFunc);
      scrollLock.enablePageScroll();
    }
    let currentlyOpenNav = null;
    this.megaNavs.forEach(nav => {
      const navItem = nav.getAttribute('data-meganav');
      nav.classList.remove(cssClasses.navActive);
      const selectedNav = document.getElementById(`${navItem}-meganav`);
      if (!selectedNav.classList.contains(cssClasses.hidden)) {
        currentlyOpenNav = navItem;
      }
      selectedNav.classList.add(cssClasses.hidden);
    });
    return currentlyOpenNav;
  };
}
