import Rellax from 'rellax';

export default class MainCollectionBanner {
  constructor(section) {
    const imageWrapper = section.el.querySelector('.rellax');

    new Rellax(imageWrapper, {
      speed: -7,
      center: !1,
      round: !0,
    });
  }
}
