import { debounce } from '../helpers';

export default class ScrollToTop {
  constructor(section) {
    this.trigger = section.el.querySelector('.ScrollToTop');
    this.trigger.addEventListener('click', e => {
      e.preventDefault();
      this._handleClick();
    });
    window.addEventListener('scroll', () => {
      this._handleVisibility();
    });
  }

  _handleClick() {
    window.scrollTo(0, 0);
  }

  _handleVisibility = debounce(() => {
    if (window.pageYOffset >= 0.75 * window.innerHeight) {
      this.trigger.classList.remove('translate-y-40');
      this.trigger.setAttribute('aria-hidden', 'false');
    } else {
      this.trigger.classList.add('translate-y-40');
      this.trigger.setAttribute('aria-hidden', 'true');
    }
  });
}
