// Wrapper class for Shopify's Predictive Search. Docs https://shopify.dev/api/ajax/reference/predictive-search
// This populates the predictive_search object in predictive-search.liquid section.
// Settings object example:
/*
{
  'type': 'product' REQUIRED
  'limit': '4'
  'fields': 'title'
  'unavailable_products': 'hide'
}
*/
export default class PredictiveSearch {
  constructor(sectionId, settings = {}) {
    if (!sectionId || !settings.type) {
      const error = new Error('Missing sectionId or type');
      throw error;
    }
    this.sectionId = `section_id=${sectionId}`;
    this.params = this.paramsBuilder(settings);
  }

  // Build the params string to be appended between query and sectionId.
  paramsBuilder(settings) {
    let params = '';
    for (const setting in settings) {
      switch (setting) {
        case 'type':
          params += `resources[type]=${settings.type}&`;
          break;
        case 'limit':
          params += `resources[limit]=${settings.limit}&`;
          break;
        case 'fields':
          params += `resources[options][fields]=${settings.fields}&`;
          break;
        case 'unavailable_products':
          params += `resources[options][unavailable_products]=${settings.unavailable_products}&`;
          break;
        default:
          break;
      }
    }
    return params;
  }

  // Query results from the predictive search api given terms and a callback to trigger when it is done.
  query(terms) {
    return fetch(`/search/suggest?q=${terms}&${this.params}${this.sectionId}`);
  }
}
