import { DropDown } from '@knitagency/agency-utils';
import Modal from '../components/Modal';
import Product from '../components/product/Product';

export default class MainProduct {
  constructor(section) {
    const product = section.el.querySelector('[data-product]');
    const { salePage, monogramable } = section.data;
    if (product) {
      new Product(product, {
        isSalePage: salePage,
      });
      this._initDropDown(section);
    }

    this.modalContainer = document.querySelector('[data-pxu-modal]');
    this.modalContent = document.getElementById('ajax-modal-content');
    this._initModals(section);

    if (monogramable) {
      this._initMonogram(section);
    }
  }

  _initDropDown(section) {
    const el = section.el.querySelector('[data-product-accordion]');
    if (el) {
      new DropDown({
        el: el,
        triggerClass: 'product-info__header',
        contentClass: 'product-info__content',
        closeOnClickOutside: false,
        closeOthers: false,
      });
    }
  }

  _initModals(section) {
    // find all modal triggers

    const modalTriggers = section.el.querySelectorAll('[data-modal-trigger]');
    if (modalTriggers.length === 0) return;
    this.modal = new Modal();

    modalTriggers.forEach(trigger => {
      const modalEl = document.querySelector(
        `[data-modal-content="${trigger.dataset.modalTrigger}"]`,
      );
      trigger.addEventListener('click', e => {
        e.preventDefault();
        if (trigger.dataset.modalTitle) {
          this.modalContainer.querySelector(
            '[data-pxu-modal-title]',
          ).innerHTML = trigger.dataset.modalTitle;
        }
        this.modalContent.innerHTML = modalEl.innerHTML;
        this.modal.open('#ajax-modal-content');
      });
    });
  }

  _initMonogram(section) {
    const monogramGuide = section.el.querySelector('.PersonalizationLink');
    const monogramButton = section.el.querySelector('.product-personalizer');
    if (monogramGuide && monogramButton) {
      monogramGuide.parentNode.insertBefore(monogramButton, monogramGuide);
      monogramButton.classList.remove('show');
    }
  }
}
