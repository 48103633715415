import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';

import { useEffect, useState } from 'preact/hooks';

const CartItemPrice = ({ item }) => {
  const [price, setPrice] = useState(item.final_line_price);
  const [preDiscount, setPreDiscount] = useState(null);

  useEffect(() => {
    setPrice(item.final_line_price);
    setPreDiscount(item.original_line_price);
  }, [item]);

  return (
    <div class="flex flex-col space-x-3 md:block md:space-x-0 md:space-y-0.5">
      {preDiscount > price && (
        <div class="type-body-2 text-right text-grey-2">
          <span class="sr-only">{getTranslation('cart.original_price')}</span>
          <span class="block line-through">
            {formatMoney(preDiscount, theme.moneyFormat)}
          </span>
        </div>
      )}
      <div class="type-body-2 text-right text-primary-1">
        {preDiscount > price && (
          <span class="sr-only">{getTranslation('cart.discounted_price')}</span>
        )}
        <span class="block">{formatMoney(price, theme.moneyFormat)}</span>
      </div>
    </div>
  );
};

export default CartItemPrice;
