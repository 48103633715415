import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { pixelBreakpoints } from '../../helpers';

import 'photoswipe/style.css';

const selectors = {
  productImage: '[data-product-image]',
  variantImage: '[data-image-variant]',
  mainCarousel: '[data-image-carousel]',
  carouselItems: '.product__media-item',
  thumbnailCarousel: '[data-thumbnail-carousel]',
};

/**
 * Product image gallery on the PDP
 *
 * @export
 * @class ProductImages
 */
export default class ProductImages {
  constructor(el, params = {}) {
    this.el = el;
    this.options = params.options;
    this.isDesktop = window.matchMedia(
      `(min-width: ${pixelBreakpoints.lg})`,
    ).matches;
    this.isMobile = window.matchMedia(
      `(max-width: ${pixelBreakpoints.lg})`,
    ).matches;
    this.mainCarousel = el.querySelector(selectors.mainCarousel);
    this.carouselItems = el.querySelectorAll(selectors.carouselItems);
    this.navItems = this.el.querySelectorAll(selectors.productImage);
    this.sliderNav = this.el.querySelector('.slider-navigation');
    this.currentPageElement = this.el.querySelector('.slider-counter--current');
    this.pageTotalElement = this.el.querySelector('.slider-counter--total');

    this.thumbnailCarousel = el.querySelector(selectors.thumbnailCarousel);
    if (this.thumbnailCarousel) {
      this.thumbItems = this.thumbnailCarousel.querySelectorAll(
        selectors.productImage,
      );
    }

    this.zoomTrigger = el.querySelector('.zoom-trigger');
    this.mobileZoomTrigger = el.querySelector('[data-open-product-zoom]');

    const resizeObserver = new ResizeObserver(entries => this._initCarousel());
    resizeObserver.observe(this.mainCarousel);

    this.mainCarousel.addEventListener('scroll', this.update.bind(this));

    this.mainCarousel.addEventListener('mousemove', e => {
      if (this.isDesktop) this._slideshowMouseMove(e);
    });

    this.mainCarousel.addEventListener('mouseleave', e => {
      if (this.isDesktop) this._slideshowMouseLeave(e);
    });

    if (this.thumbItems) {
      this.thumbItems.forEach((thumb, index) => {
        thumb.querySelector('button').addEventListener('click', elem => {
          this.onThumbClick(thumb.dataset.mediaId);
        });
      });
    }
  }

  /**
   * Initialize primary image carousel
   */
  _initCarousel() {
    this.sliderItemsToShow = Array.from(this.carouselItems).filter(
      element => element.clientWidth > 0,
    );

    if (this.sliderItemsToShow.length < 2) return;
    this.sliderItemOffset =
      this.sliderItemsToShow[1].offsetLeft -
      this.sliderItemsToShow[0].offsetLeft;

    this.slidesPerPage = Math.floor(
      (this.mainCarousel.clientWidth - this.sliderItemsToShow[0].offsetLeft) /
        this.sliderItemOffset,
    );
    this.totalPages = this.sliderItemsToShow.length - this.slidesPerPage + 1;
    this.update();
  }

  resetPages() {
    this.carouselItems = this.el.querySelectorAll(selectors.carouselItems);
    this._initCarousel();
  }

  update() {
    if (!this.mainCarousel) return;

    //Find the current item with .active class within this.sliderItemsToShow

    this.currentPage =
      Math.round(this.mainCarousel.scrollLeft / this.sliderItemOffset) + 1;

    if (this.sliderNav) {
      let sliderNavContents = '';
      for (let i = 0; i < this.totalPages; i++) {
        sliderNavContents += `<span class="swiper-pagination-bullet" role="button" tab-index="${i}" aria-label="Go to slide ${
          i + 1
        }"></span>`;
      }

      this.sliderNav.innerHTML = sliderNavContents;
      this.sliderNav.childNodes[this.currentPage - 1].classList.add(
        'swiper-pagination-bullet-active',
      );
      this.sliderNav.childNodes[this.currentPage - 1].setAttribute(
        'aria-current',
        true,
      );

      this.sliderNav.childNodes.forEach((bullet, index) => {
        bullet.addEventListener('click', e => {
          this.currentPage = index + 1;

          this.mainCarousel.scrollLeft = this.sliderItemOffset * index;
        });
      });
    }

    if (this.isDesktop) {
      this.mainCarousel.addEventListener('click', e => {
        this._initPhotoZoom();
        if (this.lightbox) {
          this.lightbox.loadAndOpen(this.currentPage - 1); // defines start slide index
        }
      });
    }

    if (this.mobileZoomTrigger) {
      this.mobileZoomTrigger.addEventListener('click', e => {
        this._initPhotoZoom();
        if (this.lightbox) {
          this.lightbox.loadAndOpen(this.currentPage - 1);
        }
      });
    }

    if (this.currentPageElement && this.pageTotalElement) {
      this.currentPageElement.textContent = this.currentPage;
      this.pageTotalElement.textContent = this.totalPages;
    }
  }

  onThumbClick(mediaId) {
    const activeMedia = this.mainCarousel.querySelector(
      `[data-media-id="${mediaId}"]`,
    );
    if (!activeMedia) return;
    this.navItems.forEach((thumb, index) => {
      thumb.classList.remove('active');
      if (thumb.dataset.mediaId == mediaId) {
        thumb.classList.add('active');
      }
    });

    this.setActiveMedia(mediaId);
  }

  /**
   * Handler for swapping images when the variant changes
   *
   * @param {Object} variant The currently selected variant
   * @public
   */
  change(variant, event) {
    const position = Object.keys(this.options).findIndex(
      e => e.toLowerCase() === 'color' || e.toLowerCase() === 'colour',
    );

    if (position === undefined || position === -1 || !this.thumbnailCarousel)
      return;

    const selectedColor = variant[`option${position + 1}`];

    let activeMedia;
    if (event && event.target.type) {
      activeMedia = this.mainCarousel.querySelector(
        `[data-image-variant="${selectedColor.toLowerCase()}"]`,
      );
    } else {
      activeMedia = this.mainCarousel.querySelector('.first-active');
    }
    this.navItems.forEach((element, index) => {
      if (event && event.target.type === 'radio') {
        if (element.dataset.imageVariant) {
          element.classList.add('hidden');

          if (
            selectedColor?.toLowerCase() ==
            element.dataset.imageVariant.toLowerCase()
          ) {
            element.classList.remove('hidden');
          } else {
            element.classList.add('hidden');
          }
        }
      }
    });

    if (activeMedia) {
      // Find the correct thumbnail to set as active
      this.navItems.forEach((thumb, index) => {
        thumb.classList.remove('active');
        if (thumb.dataset.mediaId == activeMedia.dataset.mediaId) {
          thumb.classList.add('active');
        }
      });
      this.setActiveMedia(activeMedia.dataset.mediaId);
    }
  }

  setActiveMedia(mediaId) {
    const activeMedia = this.mainCarousel.querySelector(
      `[data-media-id="${mediaId}"]`,
    );

    this.carouselItems.forEach(element => {
      element.classList.remove('active');
    });
    activeMedia.classList.add('active');
    // activeMedia.parentElement.prepend(activeMedia);
    this.resetPages();

    window.setTimeout(() => {
      this.mainCarousel.scrollTo({
        left: activeMedia.offsetLeft,
      });
    });

    const activeThumb = this.thumbnailCarousel.querySelector(
      `[data-media-id="${mediaId}"]`,
    );

    this.thumbnailCarousel
      .querySelectorAll('button')
      .forEach(element => element.removeAttribute('aria-current'));

    if (activeThumb) {
      activeThumb.querySelector('button').setAttribute('aria-current', true);
    }
  }

  _initPhotoZoom() {
    this.galleryImageSources = [];

    const backEasing = {
      in: 'cubic-bezier(0.6, -0.28, 0.7, 1)',
      out: 'cubic-bezier(0.3, 0, 0.32, 1.275)',
      inOut: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
    };

    this.thumbItems.forEach((element, index) => {
      if (element.classList.contains('hidden')) return;
      this.galleryImageSources.push({
        id: index,
        src: element.dataset.originalSrc,
        width: element.dataset.maxWidth,
        height: element.dataset.maxHeight,
      });
    });
    const options = {
      dataSource: this.galleryImageSources,
      showHideAnimationType: 'zoom',
      showAnimationDuration: 500,
      hideAnimationDuration: 500,
      showHideOpacity: 1,
      loop: false,
      closeOnVerticalDrag: false,
      allowPanToNext: false,
      pinchToClose: false,
      scaleMode: 'zoom',
      mouseMovePan: true,
      clickToCloseNonZoomable: false,
      initialZoomLevel: 'fit',
      secondaryZoomLevel: 1.5,
      maxZoomLevel: 1,
      arrowPrevSVG:
        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="#fff" class="w-10 h-10"> <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" /> </svg>',
      arrowNextSVG:
        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="#fff" class="w-10 h-10"><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" /></svg>',
      closeSVG:
        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="#fff" class="w-11 h-11"> <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /> </svg>',
      getDoubleTapZoom: function (e, t) {
        return e ? 1.6 : t.initialZoomLevel < 0.7 ? 1 : 1.33;
      },

      pswpModule: () => import('photoswipe'),
    };
    this.lightbox = new PhotoSwipeLightbox(options);
    if (this.lightbox.pswp) {
      this.lightbox.on('firstUpdate', () => {
        this.lightbox.pswp.options.easing = backEasing.out;
      });
      this.lightbox.on('initialZoomInEnd', () => {
        this.lightbox.pswp.options.easing = backEasing.inOut;
      });
      this.lightbox.on('close', () => {
        this.lightbox.pswp.options.easing = backEasing.in;
      });
    }
    this.lightbox.init();
  }

  _slideshowMouseMove(e) {
    const i = this.mainCarousel.getBoundingClientRect();
    this.zoomTrigger.classList.remove('lg:hidden');
    this.zoomTrigger.classList.remove('hidden');

    this.zoomTrigger.style.top = ''.concat(e.clientY - i.top + 40, 'px');
    this.zoomTrigger.style.left = ''.concat(e.clientX - i.left, 'px');
  }

  _slideshowMouseLeave() {
    this.zoomTrigger.classList.add('lg:hidden');
    this.zoomTrigger.classList.add('hidden');
  }
}
