import { pixelBreakpoints } from '../helpers';

// Reference: https://github.com/mdn/web-components-examples/tree/main/expanding-list-web-component

export default class ExpandingList extends HTMLUListElement {
  constructor() {
    self = super();
    // TODO: could add an `enabled-breakpoint` also
    const { disabledBreakpoint } = self.dataset;

    if (!disabledBreakpoint) {
      this.create();
    } else {
      const mql = window.matchMedia(
        `(min-width: ${pixelBreakpoints[disabledBreakpoint]})`,
      );

      // Set up the component if we're below the min width
      if (!mql.matches) {
        this.create();
      }

      mql.addEventListener('change', () => {
        // Destroy if we cross the min-width threshold getting larger
        // or set up if we cross it getting smaller
        if (mql.matches) {
          this.destroy();
        } else {
          this.create();
        }
      });
    }
  }

  create() {
    const lis = [...self.querySelectorAll(':scope > li')];

    lis.forEach(li => {
      const uls = [...li.querySelectorAll(':scope > ul')];

      if (uls.length > 0) {
        uls.forEach(ul => {
          ul.style.height = '0px';
          ul.style.overflow = 'hidden';
        });

        const trigger = li.querySelector('expanding-trigger');

        if (trigger) {
          trigger.setAttribute('role', 'button');
          trigger.setAttribute('aria-expanded', false);
          trigger.style.cursor = 'pointer';

          trigger.addEventListener('click', self.toggle);
        }
      }
    });
  }

  destroy() {
    const lis = [...self.querySelectorAll(':scope > li')];

    lis.forEach(li => {
      const uls = [...li.querySelectorAll(':scope > ul')];

      if (uls.length > 0) {
        uls.forEach(ul => {
          ul.style.height = '';
          ul.style.overflow = '';
        });

        const trigger = li.querySelector('expanding-trigger');

        if (trigger) {
          trigger.removeAttribute('role');
          trigger.removeAttribute('aria-expanded');
          trigger.style.cursor = '';

          trigger.removeEventListener('click', self.toggle);
        }
      }
    });
  }

  toggle = function (e) {
    const nextUl = e.target.nextElementSibling;

    if (nextUl.clientHeight == 0) {
      // Open
      e.target.setAttribute('aria-expanded', true);

      nextUl.style.height = 'auto';

      const ulHeight = nextUl.clientHeight + 'px';
      nextUl.style.height = '0px';

      requestAnimationFrame(() => {
        nextUl.style.height = ulHeight;
      });

      // Remove fixed height after transition ends -
      // this will make the element able to respond to
      // height changes, eg. if the browser is resized
      nextUl.addEventListener(
        'transitionend',
        () => {
          nextUl.style.height = '';
        },
        { once: true },
      );
    } else {
      // Close
      e.target.setAttribute('aria-expanded', false);

      const ulHeight = nextUl.clientHeight + 'px';
      nextUl.style.height = ulHeight;

      requestAnimationFrame(() => {
        nextUl.style.height = '0px';
      });
    }
  };
}
