import ProductForm from './ProductForm';
import ProductImages from './ProductImages';
import { formatMoney } from '@shopify/theme-currency';

const selectors = {
  priceWrapper: '[data-price-wrapper]',
  productPrice: '[data-product-price]',
  comparePrice: '[data-compare-price]',
  comparePriceText: '[data-compare-text]',
};

const cssClasses = {
  hidden: 'hidden',
};

/**
 * The wrapper class for PDP components
 *
 * @export
 * @class Product
 */
export default class Product {
  /**
   * Creates an instance of Product.
   *
   * @param {HTMLElement} el The container of this product
   * @param {Object} config Settings object
   */
  constructor(el, config = {}) {
    this.el = el;
    this.config = Object.assign(
      {
        isQuickShop: false,
        isFeaturedProduct: false,
        inlineImages: false,
        isSalePage: false,
        isMoreFromCollection: false,
      },
      config,
    );

    this.options = JSON.parse(this.el.dataset.productOptions);
    this._initComponents();
  }

  /**
   * Init all components that make up the product
   */
  _initComponents() {
    if (!this.config.inlineImages) {
      this.productImages = new ProductImages(this.el, {
        options: this.options,
      });
    }
    if (this.el.querySelector('[data-product-form]')) {
      this.productForm = new ProductForm(this.el, {
        onVariantChange: (variant, target) =>
          this._onVariantChange(variant, target),
        isQuickShop: this.config.isQuickShop,
        isFeaturedProduct: this.config.isFeaturedProduct,
        isSalePage: this.config.isSalePage,
      });
    }
  }

  /**
   * Call functions to update on variant change from product form
   *
   * @param {Object} variant
   */
  _onVariantChange = (variant, event) => {
    if (this.config.inlineImages) {
      this._updateInlineImages(variant, event);
    } else {
      this.productImages.change(variant, event);
    }
    this._updatePrice(variant);
    this._updateVariantLabel(variant);
  };

  /**
   * Update prices when selected variant changes
   *
   * @param {Object} variant
   */
  _updatePrice = variant => {
    const comparePrice = this.el.querySelectorAll(selectors.comparePrice);
    const mainPrice = this.el.querySelectorAll(selectors.productPrice);

    const isOnSale = variant.compare_at_price > variant.price;
    const comparePriceText = isOnSale
      ? formatMoney(variant.compare_at_price, theme.moneyFormat)
      : '';

    mainPrice.forEach(elem => {
      elem.innerHTML = formatMoney(variant.price, theme.moneyFormat);
    });

    // Update compare at price depending on whether variant is on sale
    if (comparePrice.length) {
      comparePrice.forEach(elem => {
        elem.innerHTML = comparePriceText;
      });
      if (isOnSale) {
        comparePrice.forEach(elem => {
          elem.classList.remove(cssClasses.hidden);
        });
        // compareText.classList.remove(cssClasses.hidden);
        mainPrice.forEach(elem => {
          elem.classList.add('text-error');
        });
      } else {
        comparePrice.forEach(elem => {
          elem.classList.add(cssClasses.hidden);
        });
        mainPrice.forEach(elem => {
          elem.classList.remove('text-error');
        });
      }
    }
  };

  /**
   * Update color label when selected variant changes
   *
   * @param {Object} variant
   */

  _updateVariantLabel(variant) {
    const variantLabels = this.el.querySelectorAll('[data-selection-label]');
    variantLabels.forEach(label => {
      label.innerHTML = variant[`${label.dataset.optionPosition}`];
    });
  }

  /**
   * Update the featured image when variant changes
   *
   * @param {Object} variant
   */

  _updateInlineImages(variant, e) {
    const position = Object.keys(this.options).findIndex(
      option =>
        option.toLowerCase() === 'color' || option.toLowerCase() === 'colour',
    );
    const imagesData = this.el.querySelector(
      '[type="application/json"][data-variant-images]',
    );

    if (position === undefined || !imagesData || position === -1) {
      return;
    }

    this.variantImages =
      this.variantImages || JSON.parse(imagesData.textContent);

    const selectedColor = variant[`option${position + 1}`];

    const variantImage = this.variantImages
      .filter(image => image.alt !== null)
      .find(image => {
        const image_alt = image.alt.toLowerCase();
        if (image_alt.includes(selectedColor.toLowerCase())) {
          return true;
        }
        return false;
      });
    if (variantImage) {
      const featuredImage = this.el.querySelector('[data-featured-image]');
      featuredImage.src = variantImage.src;
      featuredImage.srcset = '';
    }
  }
}
