import { pixelBreakpoints } from '../helpers';

const selectors = {
  popoverOverlay: '[data-modal-backdrop]',
  popoverClose: '[data-dropdown-close]',
};

export default class SelectDropdown {
  constructor(el) {
    this.el = el;
    this._init();

    this.isDesktop = window.matchMedia(
      `(min-width: ${pixelBreakpoints.lg})`,
    ).matches;
    this.isMobile = window.matchMedia(
      `(max-width: ${pixelBreakpoints.lg})`,
    ).matches;

    this.popoverOverlay = document.querySelector(selectors.popoverOverlay);
    this.popoverOverlay.addEventListener('click', e => {
      e.preventDefault();
      this._toggleDropdown();
    });

    el.addEventListener('update:dropdown', () => {
      this._updateState();
    });
  }

  // Convert the select element into a dropdown

  _init() {
    // Create the dropdown
    this.dropdown = document.createElement('div');
    this.dropdown.classList.add('select-dropdown');

    // Create the dropdown button
    this.dropdownButton = document.createElement('button');
    this.dropdownButton.classList.add('select-dropdown__button');
    this.dropdownButton.setAttribute('type', 'button');
    this.dropdownButton.setAttribute('aria-haspopup', 'true');
    this.dropdownButton.setAttribute('aria-expanded', 'false');
    this.dropdownButton.innerHTML =
      this.el.options[this.el.selectedIndex].innerHTML;
    this.dropdown.appendChild(this.dropdownButton);

    // Create the dropdown menu
    this.dropdownMenu = document.createElement('ul');
    this.dropdownWrapper = document.createElement('div');
    this.dropdownWrapper.classList.add('select-dropdown__menu');

    this.dropdownHeader = document.createElement('div');
    this.dropdownHeader.classList.add('select-dropdown__header');
    this.dropdownHeader.innerHTML = `<a role="button" class="cursor-pointer w-4 h-4 py-4 flex items-center justify-center" data-dropdown-close><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
  </svg></a><span class="flex-1 uppercase type-body-2 font-bold">${this.el.getAttribute(
    'data-option-label',
  )}</span><span class="w-4 h-4 py-4"></span>`;
    this.dropdownHeader
      .querySelector('[data-dropdown-close]')
      .addEventListener('click', this._toggleDropdown.bind(this));
    this.dropdownWrapper.appendChild(this.dropdownHeader);
    this.dropdownWrapper.appendChild(this.dropdownMenu);

    this.dropdown.appendChild(this.dropdownWrapper);

    // Create the dropdown menu items
    for (let i = 0; i < this.el.options.length; i++) {
      const option = this.el.options[i];
      const item = document.createElement('li');
      item.classList.add('select-dropdown__item');
      item.setAttribute('data-value', option.value);
      item.innerHTML = option.innerHTML;
      if (option.selected) {
        item.classList.add('font-bold');
      }
      if (option.hasAttribute('data-disabled')) {
        item.classList.add('unavailable');
      }
      this.dropdownMenu.appendChild(item);
    }

    // Insert the dropdown after the select element
    this.el.parentNode.insertBefore(this.dropdown, this.el.nextSibling);

    // Hide the select element
    this.el.style.display = 'none';

    // Add event listeners
    this._addEventListeners();
  }

  _addEventListeners() {
    this.dropdownButton.addEventListener(
      'click',
      this._toggleDropdown.bind(this),
    );
    this.dropdownMenu.addEventListener('click', this._selectOption.bind(this));
    // Close the dropdown if the user clicks outside of it
    document.addEventListener('click', this._clickOutside.bind(this));
  }

  _toggleDropdown() {
    this.dropdown.classList.toggle('select-dropdown--open');
    this.dropdownButton.setAttribute(
      'aria-expanded',
      this.dropdown.classList.contains('select-dropdown--open'),
    );

    if (this.isMobile) {
      if (this.dropdown.classList.contains('select-dropdown--open')) {
        this.popoverOverlay.classList.remove('hidden');
        document.body.classList.add('overflow-hidden');
        this.popoverOverlay.classList.add('flex');
      } else {
        this.popoverOverlay.classList.add('hidden');
        document.body.classList.remove('overflow-hidden');
        this.popoverOverlay.classList.remove('flex');
      }
    }
  }

  _selectOption(e) {
    if (e.target.classList.contains('select-dropdown__item')) {
      this.dropdownButton.innerHTML = e.target.innerHTML;
      e.target.parentNode
        .querySelectorAll('.select-dropdown__item')
        .forEach(item => {
          item.classList.remove('font-bold');
        });
      e.target.classList.add('font-bold');

      this.el.value = e.target.getAttribute('data-value');
      // Trigger a change event on the select element
      const changeEvent = new Event('change');
      this.el.dispatchEvent(changeEvent);
      this._toggleDropdown();
    }
  }

  _clickOutside(e) {
    if (
      !this.dropdown.contains(e.target) &&
      this.dropdown.classList.contains('select-dropdown--open')
    ) {
      this._toggleDropdown();
    }
  }

  _updateState() {
    for (let i = 0; i < this.el.options.length; i++) {
      const option = this.el.options[i];
      const item = this.dropdownMenu.querySelectorAll('.select-dropdown__item')[
        i
      ];
      if (option.selected) {
        item.classList.add('font-bold');
      } else {
        item.classList.remove('font-bold');
      }
      if (option.hasAttribute('data-disabled')) {
        item.classList.add('unavailable');
      } else {
        item.classList.remove('unavailable');
      }
    }
  }
}
