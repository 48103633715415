import CartUpsellProduct from './CartUpsellProduct';
import { fetchProduct } from '../helpers/queries';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import {
  getIdfromGid,
  getTranslation,
  mapEdgesToNodes,
} from '../helpers/utilities';
import { useEffect, useState } from 'preact/hooks';

const CartUpsell = () => {
  const { cart, isMiniCartOpen } = useCartContext();
  const [productIds, setProductIds] = useState([]);
  const [upsells, setUpsells] = useState([]);
  const [upsellItems, setUpsellItems] = useState([]);

  useEffect(() => {
    if (isMiniCartOpen && cart && cart.items.length > 0) {
      const upsellHandles = [];
      const fetchHandles = cart.items.map(item => item.upsell_item);

      fetchHandles.forEach(item => {
        if (item?.includes('|')) {
          const itemsToAdd = item.split('|');
          itemsToAdd.forEach(itemToAdd => {
            upsellHandles.push(itemToAdd);
          });
        } else {
          upsellHandles.push(item);
        }
      });

      setProductIds(cart.items.map(item => item.product_id));

      setUpsells([]);

      upsellHandles.forEach(handle => {
        fetchProduct(handle).then(res => {
          if (!res) {
            return;
          }
          const { product } = res.data;
          const upsell = {
            id: getIdfromGid(product.id),
            title: product.title,
            handle: product.handle,
            options: product.options,
            primary_color: product.options[0].values[0],
            variants: mapEdgesToNodes(product.variants),
            images: mapEdgesToNodes(product.images),
            price: product.priceRange,
            vendor: product.vendor,
          };
          setUpsells(prev => [...prev, upsell]);
        });
      });
    }
  }, [cart.items, isMiniCartOpen]);

  useEffect(() => {
    if (isMiniCartOpen == true) {
      const filteredUpsells = upsells.filter(
        upsell => !productIds.includes(upsell.id),
      );
      setUpsellItems(filteredUpsells);
    }
  }, [upsells, isMiniCartOpen]);

  return upsellItems && upsellItems.length > 0 ? (
    <div class="relative py-8">
      <p class="type-nav-item mb-4 font-bold uppercase">
        {getTranslation('mini_cart.complete_order')}
      </p>
      <div class="hide-scrollbar flex snap-mandatory overflow-x-auto scroll-smooth">
        {(upsellItems || []).map(item => (
          <CartUpsellProduct product={item} />
        ))}
      </div>
    </div>
  ) : null;
};

export default CartUpsell;
