import * as cartApi from '@knitagency/agency-utils/dist/cartApi';

import Quantity from '../Quantity';
import SelectDropdown from '../SelectDropdown';
import quickShop from './ProductQuickShop';
import { updateReactCart } from '../../preact/helpers/utilities';

import {
  ProductForm as ThemeProductForm,
  getUrlWithVariant,
} from '@shopify/theme-product-form';
import { deepMerge, pixelBreakpoints } from '../../helpers';

const selectors = {
  form: '[data-product-form]',
  addToCart: '[data-add-to-cart]',
  quantityInput: '[data-quantity-input]',
  productSelect: '[data-product-select]',
  swatchTrigger: '[data-swatch-trigger]',
  popoverOverlay: '[data-modal-backdrop]',
  popoverClose: '[data-popover-close]',
  bisTrigger: '.product-form__bis',
  preorderText: '[data-preorder-date]',
};

/**
 * Configuration of the PDP add to cart form
 *
 * @export
 * @class ProductForm
 */
export default class ProductForm {
  /**
   * Creates an instance of ProductForm.
   * @param {HTMLElement} productContainer The container of this product
   * @param {Object} config Settings object
   */
  constructor(productContainer, config = {}) {
    this.el = productContainer;
    this.isDesktop = window.matchMedia(
      `(min-width: ${pixelBreakpoints.lg})`,
    ).matches;
    this.isMobile = window.matchMedia(
      `(max-width: ${pixelBreakpoints.lg})`,
    ).matches;
    this.formElement = this.el.querySelector(selectors.form);
    this.quantityInput = this.el.querySelector(selectors.quantityInput);
    this.variantInventory = JSON.parse(
      productContainer.dataset.variantInventory,
    );
    this.config = {
      isQuickShop: false,
      onVariantChange: () => {},
      ...pixelThemeSettings.product,
      ...config,
    };

    this.dropdowns = this.el.querySelectorAll('[data-option-dropdown]');
    this.dropdowns.forEach(dropdown => {
      new SelectDropdown(dropdown);
    });
    this.swatches = this.el.querySelectorAll('[data-color-swatch]');

    if (this.swatches.length > 0) {
      this.hasSwatches = true;
    } else {
      this.hasSwatches = false;
    }

    if (this.hasSwatches) {
      this.swatchTrigger = this.el.querySelector(selectors.swatchTrigger);
      this.swatchTrigger.addEventListener('click', e => {
        e.preventDefault();
        this._showSwatchPopover(e);
      });
      this.swatchPopover = this.el.querySelector('[data-swatch-popover]');

      this.popoverOverlay = document.querySelector(selectors.popoverOverlay);
      this.popoverOverlay.addEventListener('click', e => {
        e.preventDefault();
        this._hideSwatchPopover(e);
      });
      this.popoverClose = this.el.querySelector(selectors.popoverClose);
      this.popoverClose.addEventListener('click', e => {
        e.preventDefault();
        this._hideSwatchPopover(e);
      });
    }
    fetch(`/products/${this.formElement.dataset.productHandle}.js`)
      .then(response => {
        return response.json();
      })
      .then(productJSON => {
        this.product = productJSON;

        this.themeProductForm = new ThemeProductForm(
          this.formElement,
          productJSON,
          {
            onOptionChange: this._onOptionChange,
            onFormSubmit: this._onFormSubmit,
          },
        );
        this.quantity = new Quantity(this.el);

        if (
          this.product.options.length > 1 &&
          this.config.removeInvalidVariants
        ) {
          this.validVariantObject = this._createValidVariantObject();
          this._removeInvalidVariants();
        }
        if (
          this.product.options.length > 1 &&
          this.config.disableUnavailableVariants
        ) {
          this.availableVariantObject = this._createAvailableVariantObject();
          this._removeUnavailableVariants();
        }

        if (this.product.options.length > 1) {
          this.validVariantObject = this._createValidVariantObject();
          this.availableVariantObject = this._createAvailableVariantObject();
          this._handleUnavailableVariants();
        }

        /**
         * On initialization, set source of truth
         */
        this._updateVariantState();

        if (this.hasSwatches) {
          this._handleVariantDisplay();

          this.el
            .querySelector('[data-product-swatches]')
            .classList.remove('opacity-0');
          this.el
            .querySelector('[data-product-swatches]')
            .classList.add('opacity-100');
        }
      });
  }

  /**
   * Called any time an option is changed
   */
  _onOptionChange = e => {
    /**
     * On option change, update source of truth
     */

    this._updateVariantState(e);

    if (this.product.options.length > 1 && this.config.removeInvalidVariants) {
      this._removeInvalidVariants();
    }
    if (
      this.product.options.length > 1 &&
      this.config.disableUnavailableVariants
    ) {
      this._removeUnavailableVariants();
    }
    if (this.product.options.length > 1) {
      this._handleUnavailableVariants();
    }

    this._updateVariantState(e);
  };

  /**
   * Custom logic to hide or show sale page variants
   *
   */

  _handleVariantDisplay() {
    if (!this.config.isFeaturedProduct) {
      // check if all variants on sale
      this.saleVars = this.product.variants.filter(
        variant => variant.compare_at_price > variant.price,
      );
      this.allOnSale = this.saleVars.length == this.product.variants.length;

      // if all variants of a product are on sale, we just show the sale info
      if (this.allOnSale) {
        this.hideFullPriceVars();
        return;
      }

      if (this.config.isSalePage) {
        this.hideFullPriceVars();
      } else {
        this.hideSaleVars();
      }
    }
  }

  hideFullPriceVars() {
    let swatchesProcessed = 0;
    this.swatches.forEach(swatch => {
      swatch.classList.add('hidden');
      const relevantSwatches = this.product.variants.filter(variant => {
        if (variant.option1 == swatch.dataset.colorSwatch) {
          return variant;
        }
      });
      const atleastOneOnSale = relevantSwatches.some(
        variant => variant.compare_at_price > variant.price,
      );
      if (atleastOneOnSale) {
        swatch.classList.remove('hidden');
      }

      swatchesProcessed++;
      if (this.swatches.length == swatchesProcessed) {
        // Hide from size dropdown products that are not on sale
        if (
          !this.selectedVariant.compare_at_price ||
          this.selectedVariant.compare_at_price < this.selectedVariant.price
        ) {
          this.selectAvailableSwatch();
        }
      }
    });
  }

  hideSaleVars() {
    let swatchesProcessed = 0;
    this.swatches.forEach(swatch => {
      const relevantSwatches = this.product.variants.filter(variant => {
        if (variant.option1 == swatch.dataset.colorSwatch) {
          return variant;
        }
      });

      const allVarsOnSale = relevantSwatches.every(
        variant => variant.compare_at_price > variant.price,
      );
      if (allVarsOnSale) {
        swatch.classList.add('hidden');
      }

      swatchesProcessed++;
      if (this.swatches.length == swatchesProcessed) {
        if (
          this.selectedVariant.compare_at_price > this.selectedVariant.price
        ) {
          this.selectAvailableSwatch();
        }
      }
    });
  }

  selectAvailableSwatch() {
    // get first available variant
    const firstAvail = this.el.querySelector(
      '[data-product-swatches] [data-color-swatch]:not(.hidden)',
    );

    if (!firstAvail) {
      return;
    }

    firstAvail.querySelector('input').click();
  }

  // Use object data, so line item properties can be added as hidden input
  convertFormDataToObject(form) {
    const formData = new FormData(form);
    const properties = {};

    for (const data of formData) {
      if (data[0].includes('properties')) {
        const propertyKey = data[0].substring(
          data[0].indexOf('[') + 1,
          data[0].lastIndexOf(']'),
        );
        properties[propertyKey] = data[1];
      }
    }

    return {
      items: [
        {
          id: formData.get('id'),
          quantity: formData.get('quantity'),
          properties,
        },
      ],
    };
  }

  /**
   * This function is called whenever the user submits the form
   *
   * @param {*} event
   */
  _onFormSubmit = event => {
    event.preventDefault();

    const productToAdd = this.convertFormDataToObject(event.target);

    cartApi
      .add(productToAdd)
      .then(() => {
        if (this.config.isQuickShop) {
          quickShop.close();
        }
        updateReactCart(true);
      })
      .catch(response => this._handleCartApiError(response));
  };

  /**
   * Updates the dom after a variant has changed
   */
  _updateVariantState = e => {
    const variant = this.themeProductForm.variant();
    this.selectedVariant = variant;
    if (variant === null) {
      this._disableAddToCartState();
      return;
    }

    if (
      !this.config.isQuickShop &&
      !this.config.isFeaturedProduct &&
      !this.config.isMoreFromCollection
    ) {
      this._updateUrl(variant);
    }
    this._updateQuantityVariant(variant);
    this.config.onVariantChange(variant, e);

    if (!variant.available) {
      this._disableAddToCartState();
    } else {
      this._enableAddToCartState();
    }

    // Update mobile  swatch trigger label to match selected variant

    if (this.hasSwatches) {
      const activeInput = this.el.querySelector(
        `input[value="${variant.option1}"]:checked`,
      );
      const activeLabelStyle = activeInput.parentElement.querySelector(
        '[data-swatch-style]',
      );
      this.el.querySelector('[data-option-value]').innerHTML = variant.option1;
      this.el.querySelector('[data-option-label]').style.backgroundImage =
        activeLabelStyle.style.backgroundImage;
      this._hideSwatchPopover();
    }
  };

  /**
   * Generic error handler for cart api
   * Example: 422 response when trying to add a product whose total stock is already in cart
   *
   * @param {Object} response The response from Shopify
   */
  _handleCartApiError = response => {
    const error = response.error;
    alert(error);
  };

  /**
   * Update the url with the selected variant's ID
   *
   * @param {Object} variant
   */
  _updateUrl(variant) {
    if (variant) {
      const url = getUrlWithVariant(window.location.href, variant.id);
      window.history.replaceState({ path: url }, '', url);
    }
  }

  /**
   * Update the quantity input with the selected variant's ID and max quantity
   *
   * @param {Object} variant
   */
  _updateQuantityVariant(variant) {
    if (variant && this.quantityInput) {
      this.quantityInput.dataset.variantId = variant.id;
      const { preorder } = this.getVariantMetafields();

      let stock = 0;
      if (variant.inventory_management === null || preorder) {
        stock = 9999;
      } else {
        stock = this.variantInventory[variant.id];
      }
      this.quantityInput.max = stock;
    }
  }

  /**
   * disable button state and text
   */
  _disableAddToCartState = () => {
    const { soldOut } = theme.strings.product;
    const button = this.el.querySelector(selectors.addToCart);

    button.setAttribute('disabled', 'disabled');
    button.innerHTML = soldOut;

    const bisTrigger = this.el.querySelector(selectors.bisTrigger);
    if (bisTrigger) {
      bisTrigger.classList.remove('hidden');
    }
  };

  /**
   * enable button state and text
   */
  _enableAddToCartState = () => {
    const { addToCart, preOrder, preOrderDate } = theme.strings.product;
    const button = this.el.querySelector(selectors.addToCart);
    const preorderText = this.el.querySelector(selectors.preorderText);
    const bisTrigger = this.el.querySelector(selectors.bisTrigger);

    button.removeAttribute('disabled');
    const { preorder } = this.getVariantMetafields();
    if (preorder != null) {
      button.innerHTML = preOrder;
      preorderText.classList.remove('hidden');
      preorderText.innerHTML = preOrderDate.replace('{{date}}', preorder);
    } else {
      button.innerHTML = addToCart;
      preorderText.classList.add('hidden');
    }
    if (bisTrigger) {
      bisTrigger.classList.add('hidden');
    }
  };

  /**
   * Map over all variants and create a
   * nested object to define them
   *
   * @param {Boolean} mapAllVariants if true map over all variants, if false only map available variants
   */
  _createVariantObject = mapAllVariants => {
    let obj = {};
    this.product.variants.forEach(variant => {
      if (variant.available || mapAllVariants) {
        const optionsCopy = [...variant.options];
        const newObj = optionsCopy
          .reverse()
          .reduce((res, key) => ({ [key]: res }), {});
        obj = deepMerge(obj, newObj);
      }
    });

    return obj;
  };

  /**
   * Create object to define invalid variants
   */
  _createValidVariantObject = () => {
    return this._createVariantObject(true);
  };

  /**
   * Create object to define available variants
   */
  _createAvailableVariantObject = () => {
    return this._createVariantObject(false);
  };

  /**
   * Mutate variant object to format of variant.options
   * @param {Object} variantObject Object to map over
   * @returns {Array} options array with only values listed in the variantObject
   */
  _mapVariantObject = variantObject => {
    const currentOptions = this.themeProductForm.options();

    let variantObjectCopy = { ...variantObject };
    const newOptions = this.product.options.map((option, index) => {
      const values = Object.keys(variantObjectCopy);

      if (variantObjectCopy[currentOptions[index]?.value]) {
        variantObjectCopy = variantObjectCopy[currentOptions[index].value];
      } else {
        variantObjectCopy =
          variantObjectCopy[Object.keys(variantObjectCopy)[0]];
      }

      return {
        ...option,
        values,
      };
    });

    return newOptions;
  };

  /**
   * Remove options that are not valid variants and pass
   * them to function to update DOM
   */
  _removeInvalidVariants = () => {
    const newOptions = this._mapVariantObject(this.validVariantObject);

    if (this.config.variantDisplayType === 'radio') {
      this._removeInvalidRadioButtons(newOptions);
    } else {
      this._removeInvalidSelectOptions(newOptions);
    }
  };

  /**
   * Update Select options with only the options of valid variants
   * @param {Array} newOptions array of options to display
   */
  _removeInvalidSelectOptions = newOptions => {
    const currentOptions = this.themeProductForm.options();

    newOptions.forEach((option, index) => {
      const select = this.el.querySelector(
        `[data-option-position="${option.position}"]`,
      );
      select.options.length = 0;
      option.values.forEach(value => {
        const isSelected = currentOptions[index].value === value;
        select.appendChild(new Option(value, value, isSelected, isSelected));
      });
    });
  };

  /**
   * Map over radio buttons and apply mutations to them if they are in the new options
   * @param {Array} newOptions options that are valid
   * @param {Function} optionNotIncluded Function to run if option is not included in the new options
   * @param {Function} optionIncluded Function to run if the option is included in the new options
   */
  _mutateRadioButtons = (newOptions, optionNotIncluded, optionIncluded) => {
    this.product.options.forEach((option, index) => {
      const radioGroup = document.getElementById(`option-${option.position}`);

      option.values.forEach(value => {
        const newOptionValues = newOptions[index].values;
        const firstAvailableInput = radioGroup.querySelector(
          `input[value="${newOptionValues[0]}"]`,
        );
        const input = radioGroup.querySelector(`input[value="${value}"]`);
        const inputParent = input.parentElement;

        if (!newOptionValues.includes(value)) {
          optionNotIncluded(inputParent, input);
          if (input.checked) {
            if (firstAvailableInput) {
              firstAvailableInput.checked = 'checked';
            }
            const event = document.createEvent('HTMLEvents');
            event.initEvent('change', true, false);
            input.dispatchEvent(event);
          }
        } else {
          optionIncluded(inputParent, input);
        }
      });
    });
  };

  /**
   * Map over color swatches and apply mutations to them if they are in the new options
   * @param {Array} newOptions options that are valid
   * @param {Function} optionNotIncluded Function to run if option is not included in the new options
   * @param {Function} optionIncluded Function to run if the option is included in the new options
   */
  _mutateColorSwatches = (optionNotIncluded, optionIncluded) => {
    if (!this.el.querySelector(`[data-product-swatches]`)) return;

    const colorOption = this.product.options.find(
      option =>
        option.name.toLowerCase() === 'color' ||
        option.name.toLowerCase() === 'colour',
    );
    const nonColorOptions = this.product.options.filter(
      option =>
        option.name.toLowerCase() !== 'color' &&
        option.name.toLowerCase() !== 'colour',
    );
    const currentOptions = this.themeProductForm.options();

    const radioGroup = this.el.querySelector(`.option-${colorOption.position}`);
    if (!radioGroup) return;

    colorOption.values.forEach(value => {
      const input = radioGroup.querySelector(`input[value="${value}"]`);
      const inputParent = input.parentElement;
      const relatedVariant = this.product.variants.find(
        variant =>
          variant[`option${colorOption.position}`] === value &&
          nonColorOptions.every(
            option =>
              variant[`option${option.position}`] ===
              currentOptions.find(
                currentOption => currentOption.name === option.name,
              ).value,
          ),
      );

      if (!relatedVariant || !relatedVariant.available) {
        optionNotIncluded(inputParent, input);
        if (input.checked) {
          const event = document.createEvent('HTMLEvents');
          event.initEvent('change', true, false);
          input.dispatchEvent(event);
        }
      } else {
        optionIncluded(inputParent, input);
      }
    });
  };

  /**
   * Remove radio buttons from the page if they are invalid variants
   * @param {Array} newOptions array of valid variants
   */
  _removeInvalidRadioButtons = newOptions => {
    const optionNotIncluded = inputParent => {
      inputParent.style.display = 'none';
    };

    const optionIncluded = inputParent => {
      inputParent.style.display = null;
    };

    this._mutateRadioButtons(newOptions, optionNotIncluded, optionIncluded);
  };

  /**
   * Remove options that are not available variants and pass
   * them to function to update DOM
   */
  _removeUnavailableVariants = () => {
    const newOptions = this._mapVariantObject(this.availableVariantObject);

    this._disableSelectOptions(newOptions);
    this._disableRadioButtons(newOptions);
  };

  /**
   * Disable Select options when they are not in the list of available variants in newOptions
   * @param {Array} newOptions array of options that are available
   */
  _disableSelectOptions = newOptions => {
    newOptions.forEach(option => {
      const select = this.el.querySelector(
        `select[data-option-position="${option.position}"]`,
      );
      if (!select) return;
      // Check if currently selected value will be disabled
      let selectionLost = !option.values.includes(
        select.options[select.selectedIndex].value,
      );

      // Loop over all options and disable the ones not in the newOptions
      Array.from(select.options).forEach(optionEl => {
        if (!option.values.includes(optionEl.value)) {
          optionEl.disabled = true;
        } else {
          // Select the first available option if currently selected gets disabled
          if (selectionLost) {
            optionEl.selected = true;
            selectionLost = false;
          }
        }
      });
    });

    // Update Select Dropdown UI afterwards

    this.dropdowns.forEach(dropdown => {
      dropdown.dispatchEvent(new CustomEvent('update:dropdown'));
    });
  };

  /**
   * Disable radio buttons if they are unavailable
   * @param {Array} newOptions array of available variants
   */
  _disableRadioButtons = newOptions => {
    const optionNotIncluded = (inputParent, input) => {
      inputParent.classList.add('opacity-50');
      input.disabled = true;
    };

    const optionIncluded = (inputParent, input) => {
      inputParent.classList.remove('opacity-50');
      input.disabled = false;
    };

    this._mutateRadioButtons(newOptions, optionNotIncluded, optionIncluded);
  };

  /**
   * Disable color swatches from the page if they are invalid variants
   * @param {Array} newOptions array of valid variants
   */
  _disableInvalidSwatches = () => {
    const optionNotIncluded = (inputParent, input) => {
      inputParent.classList.add('unavailable');
    };

    const optionIncluded = (inputParent, input) => {
      inputParent.classList.remove('unavailable');
    };

    this._mutateColorSwatches(optionNotIncluded, optionIncluded);
  };

  /**
   * Add attribute to unavailable variants since we don't want them fully disabled
   * in order for Klaviyo to work properly
   */

  _handleUnavailableVariants = () => {
    const newOptions = this._mapVariantObject(this.availableVariantObject);
    this._disableInvalidSwatches();

    newOptions.forEach(option => {
      const select = this.el.querySelector(
        `select[data-option-position="${option.position}"]`,
      );
      if (!select) return;

      // Loop over all options and disable the ones not in the newOptions
      Array.from(select.options).forEach(optionEl => {
        if (!option.values.includes(optionEl.value)) {
          optionEl.setAttribute('data-disabled', true);
        } else {
          optionEl.removeAttribute('data-disabled');
        }
      });
    });

    // Update Select Dropdown UI afterwards

    this.dropdowns.forEach(dropdown => {
      dropdown.dispatchEvent(new CustomEvent('update:dropdown'));
    });
  };

  _showSwatchPopover = () => {
    this.swatchPopover.classList.add('flex');
    this.popoverOverlay.classList.remove('hidden');
    document.body.classList.add('overflow-hidden');
    this.popoverOverlay.classList.add('flex');
    this.swatchPopover.classList.remove('hidden');
    this.swatchPopover.classList.add('translate-y-0');

    this.swatchPopover.classList.remove('translate-y-full');
  };

  _hideSwatchPopover = () => {
    this.swatchPopover.classList.remove('flex');
    this.popoverOverlay.classList.add('hidden');
    document.body.classList.remove('overflow-hidden');
    this.popoverOverlay.classList.add('hidden');
    this.popoverOverlay.classList.remove('flex');
    this.swatchPopover.classList.add('translate-y-full');
    this.swatchPopover.classList.remove('translate-y-0');
  };

  getVariantMetafields = () => {
    const selectedVariant = this.themeProductForm.variant();
    const dataScript = this.el.querySelector(`[data-variant-metafields]`);

    if (!dataScript) return;
    const variantInformation = JSON.parse(dataScript.textContent)[
      selectedVariant.id
    ];
    return variantInformation;
  };
}
