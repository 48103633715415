import CartEmpty from './CartEmpty';
import CartItem from './CartItem';
import CartTotals from './CartTotals';
import CartUpsell from './CartUpsell';
import { useCartContext } from '../context/CartContext';
import useEmptyPortal from '../hooks/useEmptyPortal';

import { Fragment, h } from 'preact';
import { createPortal, useEffect, useState } from 'preact/compat';
import { getTranslation, toggleItemToCart } from '../helpers/utilities';

const CartPageEl = document.getElementById('preact-full-cart');
const giftWrapToggle = document.getElementById('gift-wrap-toggle');
const giftWrapProduct = window.theme.strings.cart.giftwrap.product;
const nogiftWrapTag = window.theme.strings.cart.giftwrap.no_giftwrap_tag;

const CartPage = () => {
  const { cart } = useCartContext();
  const emptied = useEmptyPortal(CartPageEl);
  const [giftWrappable, setGiftWrappable] = useState(false);

  if (!emptied) {
    return null;
  }

  useEffect(() => {
    const giftWrappingItem = cart.items.find(
      item => item.id === giftWrapProduct,
    );

    const canBeGiftWrapped = cart.items.some(item => {
      return !item.product_tags?.includes(nogiftWrapTag);
    });

    if (canBeGiftWrapped && giftWrappingItem) {
      setGiftWrappable(true);
    } else {
      setGiftWrappable(false);
    }
    if (!cart?.items?.length) {
      const productRecs = document.querySelector(
        '.main-product-recommendations',
      );
      productRecs ? productRecs.classList.add('hidden') : null;
    }
  }, [cart]);

  if (giftWrapToggle) {
    giftWrapToggle.addEventListener('change', e => {
      const { checked } = e.target;

      const giftWrappingItem = cart.items.find(
        item => item.id === giftWrapProduct,
      );

      const canBeGiftWrapped = cart.items.some(item => {
        return !item.product_tags?.includes(nogiftWrapTag);
      });

      if (checked && canBeGiftWrapped && !giftWrappingItem) {
        toggleItemToCart(giftWrapProduct, 'add');
      } else {
        toggleItemToCart(giftWrapProduct, 'remove');
      }
    });
  }

  return createPortal(
    <Fragment>
      {cart.items && cart.items.length > 0 ? (
        <div class="w-full">
          <div class="hidden space-x-4 border-b border-primary-1 pb-2 lg:flex">
            <h4 class="type-body-3 uppercase ">
              {getTranslation('cart.items.product')}
            </h4>
            <h4 class="type-body-3 flex-1 basis-2/3 uppercase "></h4>
            <h4
              class="type-body-3 basis-1/5 uppercase "
              style="text-align: center"
            >
              {getTranslation('cart.items.quantity')}
            </h4>
            <h4 class="type-body-3 uppercase " style="text-align: right">
              {getTranslation('cart.items.total')}
            </h4>
          </div>
          {cart.items.map(item => (
            <CartItem
              key={item.id}
              item={item}
              page={true}
              giftable={giftWrappable}
            />
          ))}
        </div>
      ) : (
        <CartEmpty />
      )}

      <CartUpsell />

      {cart.total_price > 0 && (
        <div class="w-full border-t border-primary-1 pt-6">
          <CartTotals cart={{ ...cart }} page={true} />
        </div>
      )}
    </Fragment>,
    CartPageEl,
  );
};

export default CartPage;
