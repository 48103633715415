import cn from 'classnames';
import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const CartTotals = ({ page = false }) => {
  const {
    cart: { cart_level_discount_applications, total_price },
  } = useCartContext();

  return (
    <div>
      <div
        class={cn(
          'mt-5 md:mt-6',
          page
            ? 'type-body-2 flex items-center justify-end space-x-2 font-bold uppercase'
            : 'hidden',
        )}
      >
        <p>{getTranslation('cart.subtotal')}:</p>
        <p>{formatMoney(total_price, theme.moneyFormat)}</p>
      </div>
      {/* Discounts */}
      <div class="mt-4 flex flex-col items-end space-y-2">
        {cart_level_discount_applications.length > 0 &&
          cart_level_discount_applications.map(item => (
            <div class="type-body-2">
              {item.title} (-
              {formatMoney(item.total_allocated_amount, theme.moneyFormat)})
            </div>
          ))}
      </div>
    </div>
  );
};

export default CartTotals;
