import ProductItem from '../components/product/ProductItem';
import initCarousel from '../helpers/carousel';
import { unitlessBreakpoints } from '../helpers/breakpoints';

const selectors = {
  productItem: '[data-product-item]',
};

export default class DynamicFeaturedCollection {
  constructor(section) {
    // Init product items
    const productItems = section.el.querySelectorAll(selectors.productItem);
    const settings = JSON.parse(
      section.el.querySelector(`[data-section-id=${section.id}]`).innerHTML,
    );
    [...productItems].forEach(productItem => {
      new ProductItem(productItem);
    });

    // If the limit is set to 3 make the default perpage for lg 3
    if (settings.product_limit === 3) {
      initCarousel(section.el, {
        breakpoints: { [unitlessBreakpoints.lg]: { perPage: 3 } },
      });
    } else {
      initCarousel(section.el);
    }
  }
}
