import CartEmpty from './CartEmpty';
import CartItem from './CartItem';
import CartTotals from './CartTotals';
import CartUpsell from './CartUpsell';
import FocusTrap from 'focus-trap-react';
import cn from 'classnames';
import { debounce } from '../../helpers';
import { formatMoney } from '@shopify/theme-currency';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import { createPortal, useEffect, useState } from 'preact/compat';
import { getTranslation, toggleItemToCart } from '../helpers/utilities';

const MiniCartDrawerEl = document.getElementById('preact-mini-cart-drawer');

const transitionLength = 300;
const giftWrapProduct = window.theme.strings.cart.giftwrap.product;
const nogiftWrapTag = window.theme.strings.cart.giftwrap.no_giftwrap_tag;

const MiniCart = () => {
  const [discount, setDiscount] = useState('');
  const [giftWrapping, setGiftWrapping] = useState(false);
  const [enableGiftWrapping, setEnableGiftWrapping] = useState(false);
  const [cartNote, setCartNote] = useState('');
  const {
    cart,
    isMiniCartOpen,
    toggleMiniCart,
    miniCartData,
    updateCartAttributes,
  } = useCartContext();

  let percentage = 0;

  const thresholdInCents = miniCartData.settings.freeShippingThreshold * 100;
  const difference = thresholdInCents - cart.total_price;

  if (difference > 0) {
    percentage = (cart.total_price * 100) / thresholdInCents;
  } else {
    percentage = 100;
  }

  useEffect(() => {
    if (cart.note) {
      setCartNote(cart.note);
    }
  }, []);

  useEffect(() => {
    console.log('cart', cart);
  }, [cart]);

  useEffect(() => {
    const giftWrappingItem = cart.items.find(
      item => item.id === giftWrapProduct,
    );

    const canBeGiftWrapped = cart.items.some(item => {
      return !item.product_tags?.includes(nogiftWrapTag);
    });

    if (canBeGiftWrapped) {
      setEnableGiftWrapping(true);
    }

    if (canBeGiftWrapped && giftWrappingItem) {
      setGiftWrapping(true);
    } else {
      setGiftWrapping(false);
    }
  }, [isMiniCartOpen]);

  const toggleGiftWrapping = value => {
    setGiftWrapping(value);
    const giftWrappingItem = cart.items.find(
      item => item.id === giftWrapProduct,
    );
    if (value && !giftWrappingItem) {
      toggleItemToCart(giftWrapProduct, 'add').then(() => {});
    } else {
      toggleItemToCart(giftWrapProduct, 'remove').then(() => {});
    }
  };

  const updateCartNote = value => {
    setCartNote(value);
    debounce(() => {
      updateCartAttributes({ note: value }).then(() => {});
    }, 300);
  };

  return createPortal(
    <FocusTrap active={isMiniCartOpen}>
      <div
        class={cn(
          'fixed inset-0',
          isMiniCartOpen
            ? 'opacity-1 visible'
            : `invisible opacity-0 delay-${transitionLength}`,
        )}
      >
        <div
          onClick={() => toggleMiniCart(false)}
          class={cn(
            `absolute inset-0 bg-grey-1 transition-opacity duration-${transitionLength}`,
            isMiniCartOpen ? 'opacity-50' : 'opacity-0',
          )}
        />
        <div
          class={cn(
            `absolute top-0 bottom-0 right-0 z-50 flex w-11/12 flex-col bg-white transition-transform sm:w-full md:max-w-sm duration-${transitionLength}`,
            isMiniCartOpen ? 'translate-x-0' : 'translate-x-full',
          )}
        >
          <div class="flex items-center justify-between border-b border-b-grey-6 py-4 px-4">
            <a
              class="type-body-2 font-bold uppercase"
              href={window.theme.routes.cart}
            >
              {getTranslation('cart.general.title')}
            </a>

            <button
              class="flex items-center justify-end"
              onClick={() => toggleMiniCart(false)}
            >
              <span class="type-body-3 mr-1 uppercase">
                {getTranslation('icons.close')}
              </span>

              <svg
                className="h-2.5 w-2.5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 16 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0L1 14m14 0L1 0"
                  stroke="currentColor"
                  fill="none"
                  fill-rule="evenodd"
                />
              </svg>
            </button>
          </div>

          {miniCartData?.settings?.freeShippingIncentive && (
            <div class="pt-4 pb-6 text-center md:py-6">
              <div class="mb-2 flex items-center justify-between">
                {miniCartData?.settings?.freeShippingThreshold > 0 && (
                  <div class="type-small">
                    {percentage === 100
                      ? getTranslation('mini_cart.freeShippingCompleteMessage')
                      : getTranslation('mini_cart.freeShippingMessage', {
                          amount: formatMoney(difference, theme.moneyFormat),
                        })}
                  </div>
                )}
              </div>

              {miniCartData?.settings?.freeShippingThreshold > 0 && (
                <div class="h-2 w-full rounded-full bg-grey-6">
                  <div
                    class="h-2 rounded-full bg-primary-1"
                    style={{ width: `${percentage.toFixed(2)}%` }}
                  ></div>
                </div>
              )}
            </div>
          )}

          <div
            class="flex-1 overflow-auto px-6 md:px-8"
            data-cart-items
            data-scroll-lock-scrollable
          >
            {cart.items && cart.items.length > 0 ? (
              cart.items.map(item => (
                <CartItem item={item} key={item.id} giftable={giftWrapping} />
              ))
            ) : (
              <CartEmpty />
            )}
          </div>

          {miniCartData?.settings?.enableCartUpsells && (
            <div>
              <CartUpsell />
            </div>
          )}
          {cart.total_price > 0 && (
            <div class="mt-auto border-t border-t-primary-1 px-6 pb-6 md:px-8 md:pb-8">
              <CartTotals cart={{ ...cart }} />

              <div class="mt-8">
                {miniCartData?.settings?.enableGiftWrap &&
                  enableGiftWrapping && (
                    <div class="mb-4">
                      <input
                        type="checkbox"
                        class="peer"
                        onChange={e => toggleGiftWrapping(e.target.checked)}
                        id="GiftWrapToggle"
                        checked={giftWrapping}
                      />
                      <label
                        class="type-body-3 ml-2 uppercase"
                        for="GiftWrapToggle"
                        dangerouslySetInnerHTML={{
                          __html: window.theme.strings.cart.giftwrap.label,
                        }}
                      ></label>
                      <div class="h-0 opacity-0 transition-height duration-300 ease-in-out peer-checked:mt-4 peer-checked:h-24 peer-checked:opacity-100">
                        <textarea
                          name="note"
                          rows="4"
                          class="h-full"
                          placeholder={getTranslation(
                            'mini_cart.gift_note_placeholder',
                          )}
                          onChange={e => updateCartNote(e.target.value)}
                        >
                          {cartNote}
                        </textarea>
                      </div>
                    </div>
                  )}

                {miniCartData?.settings?.enablePromoCode && (
                  <input
                    type="text"
                    class="mb-4"
                    value={discount}
                    placeholder={getTranslation('mini_cart.promo_code')}
                    onChange={e => setDiscount(e.target.value)}
                  />
                )}

                {miniCartData?.settings?.enableShippingNotice && (
                  <p class="type-body-3 mb-2 text-center text-primary-1">
                    {getTranslation('cart.general.shipping_and_taxes_notice')}
                  </p>
                )}

                <form
                  action={`${Shopify.routes.root}cart${
                    discount ? `?discount=${discount}` : ''
                  }`}
                  method="POST"
                >
                  <button
                    type="submit"
                    name="checkout"
                    class="button button--primary button--primary-animated mt-4 inline-flex w-full items-center justify-center"
                  >
                    <span>{getTranslation('mini_cart.checkout')}</span>
                    <span class="mx-4 inline-block h-3 w-px bg-current"></span>
                    <p>{formatMoney(cart.total_price, theme.moneyFormat)}</p>
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </FocusTrap>,
    MiniCartDrawerEl,
  );
};

export default MiniCart;
