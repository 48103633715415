import CartItemPrice from './CartItemPrice';
import CartRemove from './CartRemove';
import QuantitySelector from '../shared-components/QuantitySelector';
import cn from 'classnames';
import { getSizedImageUrl } from '@shopify/theme-images';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useState } from 'preact/hooks';

const nogiftWrapTag = window.theme.strings.cart.giftwrap.no_giftwrap_tag;

const CartItem = ({ item, page = false, giftable = false }) => {
  const isMonogramFee = item.sku === 'MONOGRAMFEE';
  const [error, setError] = useState();

  return (
    <div class="relative flex py-5 md:py-7">
      {item.featured_image && item.featured_image.url && (
        <div class="mr-3 w-20">
          <a
            href={item.url}
            class={cn(
              'block aspect-product-item',
              isMonogramFee && 'pointer-events-none',
            )}
          >
            <img
              class="h-full w-full object-cover"
              alt={item.featured_image.alt}
              src={getSizedImageUrl(item.featured_image.url, 'medium')}
            />
          </a>
        </div>
      )}

      <div class="flex-1">
        <div
          class={cn(
            'flex flex-col items-start space-y-2',
            page && 'flex-col lg:flex-row lg:items-center lg:space-x-4',
          )}
        >
          <div class={cn('flex-1', page && 'basis-3/4 lg:order-1')}>
            <a
              href={item.url}
              class={cn(
                'type-cart-item-title mb-1 block',
                isMonogramFee && 'pointer-events-none',
              )}
            >
              {item.product_title}
            </a>
            <div class="flex flex-col flex-wrap">
              {!item.product_has_only_default_variant &&
                item.options_with_values.map(({ name, value }) => (
                  <div class="space-x-2">
                    <span class="type-body-3 font-bold uppercase">{name}</span>
                    <span class="type-body-3 uppercase">{value}</span>
                  </div>
                ))}
            </div>

            {item.properties && (
              <div class={cn('mt-2 flex flex-col', !page && 'hidden')}>
                {Object.entries(item.properties).map(
                  ([key, val]) =>
                    val &&
                    !key.startsWith('_') && (
                      <p class="type-small font-serif uppercase">
                        {key}:{' '}
                        {val.includes('/uploads/') ? (
                          <a href={val}>{val.split('/').pop()}</a>
                        ) : (
                          val
                        )}
                      </p>
                    ),
                )}
              </div>
            )}

            {/* Gift Wrapping */}

            {page && giftable && item.product_tags?.includes(nogiftWrapTag) && (
              <div class="type-small font-serif uppercase">
                {getTranslation('cart.general.no_giftwrap')}
              </div>
            )}
          </div>

          {item.properties && (
            <div class={cn('flex flex-col', page && 'hidden')}>
              {Object.entries(item.properties).map(
                ([key, val]) =>
                  val &&
                  !key.startsWith('_') && (
                    <p class="type-small font-serif uppercase">
                      {key}:{' '}
                      {val.includes('/uploads/') ? (
                        <a href={val}>{val.split('/').pop()}</a>
                      ) : (
                        val
                      )}
                    </p>
                  ),
              )}
            </div>
          )}

          {/* Gift Wrapping */}

          {!page && giftable && item.product_tags?.includes(nogiftWrapTag) && (
            <div class="type-small font-serif uppercase">
              {getTranslation('cart.general.no_giftwrap')}
            </div>
          )}

          <div class={cn(page && 'lg:order-3')}>
            <CartItemPrice item={item} />
          </div>
          {item.handle !== 'gift-wrap' && (
            <div
              class={cn(
                'flex items-center',
                page
                  ? 'w-full flex-row justify-between lg:order-2 lg:w-auto lg:basis-1/4 lg:flex-col lg:items-center lg:space-y-3'
                  : 'w-full flex-row justify-between',
              )}
            >
              {!isMonogramFee && (
                <>
                  <QuantitySelector
                    item={item}
                    setError={setError}
                    page={page}
                  />
                  <CartRemove item={item} />
                </>
              )}
            </div>
          )}
        </div>

        {/* Discounts */}
        {item.line_level_discount_allocations.length > 0 && (
          <div class="space-y-2">
            {Object.entries(item.line_level_discount_allocations).map(
              ([key, value]) => (
                <div key={key} class="type-small">
                  {value.discount_application.title}
                </div>
              ),
            )}
          </div>
        )}
      </div>

      {error && (
        <div class="type-overline absolute bottom-1 right-0 text-error">
          {getTranslation('cart.quantity_error', { quantity: error })}
        </div>
      )}
    </div>
  );
};

export default CartItem;
