module.exports = {
  content: [
    './layout/**/*.liquid',
    './sections/**/*.liquid',
    './snippets/**/*.liquid',
    './templates/**/*.liquid',
    './src/scripts/**/*.{js,jsx}',
    './assets/**/*.js',
  ],
  safelist: [
    'md:col-span-1',
    'md:col-span-2',
    'md:col-span-3',
    'md:col-span-4',
    'md:col-span-5',
    'md:col-span-6',
    'md:col-span-7',
    'md:col-span-8',
    'md:col-span-9',
    'md:col-span-10',
    'md:col-span-11',
    'md:col-span-12',
    'lg:col-span-1',
    'lg:col-span-2',
    'lg:col-span-3',
    'lg:col-span-4',
    'lg:col-span-5',
    'lg:col-span-6',
    'lg:col-span-7',
    'lg:col-span-8',
    'lg:col-span-9',
    'lg:col-span-10',
    'lg:col-span-11',
    'lg:col-span-12',
  ],
  theme: {
    colors: {
      primary: {
        1: '#484848',
        2: '#e4e4e4',
      },
      grey: {
        0: '#f5f5f5',
        1: '#292929',
        2: '#8c8c8c',
        3: '#bababa',
        4: '#f8f8f8',
        5: '#cecfd0',
        6: '#777b7f',
        7: '#5a5a5a',
        8: '#f4f4f4',
      },
      transparent: 'transparent',
      black: 'black',
      white: 'white',
      current: 'currentColor',
      success: '#4cc258',
      warning: '#f48e2f',
      error: '#c41010',
    },
    fontFamily: {
      sans: '"Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      serif: "'Garamond', serif",
    },
    screens: {
      sm: '576px',
      md: '820px',
      lg: '1024px',
      xl: '1240px',
    },
    extend: {
      aspectRatio: {
        'product-item': '1 / 1',
        fullscreen: '4 / 3',
      },
      spacing: {
        15: '3.75rem',
      },
      fontSize: {
        nav: '0.8125rem',
      },
      gridTemplateColumns: {
        'flexible-6': 'repeat(6, 1fr)',
        'flexible-12': 'repeat(12, 1fr)',
        13: 'repeat(13, minmax(0, 1fr))',
      },
      maxHeight: {
        112: '28rem',
        128: '32rem',
      },
      transitionProperty: {
        height: 'height',
        'background-size': 'background-size',
      },
      zIndex: {
        '-1': '-1',
      },
    },
  },
  plugins: [require('@tailwindcss/forms')],
};
