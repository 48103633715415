import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';

const CartEmpty = () => {
  return (
    <div class="mt-4 text-center">
      <p class="type-cart-item-title mb-4">{getTranslation('cart.empty')}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: getTranslation('cart.keep_shopping_html'),
        }}
      />
    </div>
  );
};

export default CartEmpty;
