import { default as SiteLocaleEn } from '../../../../locales/en.default.json';

/**
 * This function can be used for locale translation in javascript files
 *
 * @param {string} string the translation string to be used. eg: 'product.sale'
 * @param {object} replacements an object with each key being the string to be replaced and the value being what to replace it with.
 */
const getTranslation = (string, replacements) => {
  const languages = {
    en: SiteLocaleEn,
  };

  let translation = string
    .split('.')
    .reduce((acc, cur) => acc[cur], languages[Shopify.locale]);

  if (replacements) {
    Object.entries(replacements).forEach(([key, val]) => {
      translation = translation.replaceAll(`{{ ${key} }}`, val);
    });
  }

  return translation;
};

/**
 * This function can strips the is from a string
 * @param {string} string the string to be stripped
 *
 */

const getIdfromGid = string => {
  const value = string.split('/').pop();
  return parseInt(value);
};

const mapEdgesToNodes = data => {
  if (!data?.edges) {
    return [];
  }

  return data.edges.map(edge => edge.node);
};

/**
 * This adds an item to cart without the depency of a submitted form
 * @param {string} id
 * @param {string} action
 */

const toggleItemToCart = async (id, action) => {
  if (!id) return;

  const formData = new FormData();
  formData.append('id', id);
  formData.append('quantity', action === 'add' ? 1 : 0);

  const actionUrl = action === 'add' ? 'cart/add.js' : 'cart/change.js';

  return await fetch(window.Shopify.routes.root + actionUrl, {
    method: 'POST',
    body: formData,
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      updateReactCart(true);
      document.querySelector('[data-cart-items]').scrollTop = 0;
      return data;
    })
    .catch(error => {
      console.error(error);
    });
};

/**
 * This triggers an update on the react cart and upsells
 * @param {boolean} openMiniCart
 * @param {string} upsellItem
 */

const updateReactCart = openMiniCart => {
  const preactApp = document.querySelector('#pxu-preact-app');
  preactApp.dispatchEvent(
    new CustomEvent('updateReactCart', { detail: { openMiniCart } }),
  );
};

export {
  getTranslation,
  updateReactCart,
  mapEdgesToNodes,
  getIdfromGid,
  toggleItemToCart,
};
