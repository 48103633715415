import { createPortal } from 'preact/compat';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';
import useEmptyPortal from '../hooks/useEmptyPortal';
const MiniCartEl = document.getElementById('preact-mini-cart');

const MiniCart = () => {
  const { cart, toggleMiniCart } = useCartContext();
  const emptied = useEmptyPortal(MiniCartEl);
  const cartCount = cart.items.reduce((acc, item) => {
    if (item.sku != 'MONOGRAMFEE') {
      return acc + item.quantity;
    }
    return acc;
  }, 0);

  if (!emptied) {
    return null;
  }

  return createPortal(
    <button
      class="inline-flex items-center"
      onClick={() => {
        toggleMiniCart(true);
      }}
    >
      <svg
        className="mr-1 h-5 w-5 lg:h-4 lg:w-4"
        role="presentation"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 33.5"
        fill="none"
      >
        <path
          d="M30,32.4L28.5,8.6c0-0.6-0.5-1-1-1h-5.5V5.9C21.9,2.7,18.8,0,15,0c-3.8,0-6.9,2.7-6.9,5.9v1.6H2.5 c-0.6,0-1,0.4-1,1L0,32.4c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3H29c0.3,0,0.6-0.1,0.8-0.3C29.9,32.9,30,32.7,30,32.4z M10.2,5.9c0-2.1,2.2-3.9,4.8-3.9c2.7,0,4.8,1.7,4.8,3.9v1.6h-9.7V5.9z M2.2,31.4l0.4-5.8l0.1-1.4L3.5,9.7h4.6v2.3c0,0.6,0.5,1,1,1 c0.6,0,1-0.5,1-1V9.7h9.7v2.3c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1V9.7h4.6l0.9,14.5l0.1,1.4l0.4,5.8H2.2z"
          fill="currentColor"
        />
      </svg>
      <span class="type-body-3">({cart.items ? cartCount : 0})</span>
    </button>,
    MiniCartEl,
  );
};

export default MiniCart;
