import { pixelBreakpoints } from '../helpers';

const selectors = {
  mobileSortWrapper: '.boost-pfs-filter-toolbar-top-mobile-inner',
  mobileSortOptions: '.boost-pfs-filter-filter-dropdown',
  mobileSortButton: '.boost-pfs-filter-top-sorting-wrapper',
  popoverOverlay: '[data-modal-backdrop]',
};

export default class MainBoostCollection {
  constructor(section) {
    this.el = section.el;

    this.isDesktop = window.matchMedia(
      `(min-width: ${pixelBreakpoints.lg})`,
    ).matches;
    this.isMobile = window.matchMedia(
      `(max-width: ${pixelBreakpoints.lg})`,
    ).matches;
    //Listen for Boost re-rendering the page
    window.addEventListener('boostItemsRenders', () => {
      this._initDesktopPopover(section);

      this._initMobilePopover(section);
    });
    this.popoverOverlay = document.querySelector(selectors.popoverOverlay);
    this.popoverOverlay.addEventListener('click', () => {
      this._hidePopover();
    });
  }
  _initDesktopPopover() {
    this.filterTreeDesktop = this.el.querySelector(
      '[data-filter-tree-desktop]',
    );
    this.filterButtonDesktop = this.el.querySelector(
      '[data-filter-desktop-button]',
    );
    this.desktopToolbar = this.el.querySelector('[data-desktop-toolbar]');

    this.filterButtonDesktop.insertAdjacentElement(
      'afterend',
      this.filterTreeDesktop,
    );
    // if click does not contain filter tree, close it

    if (this.isDesktop) {
      const filterTreeOpenBody = 'boost-pfs-filter-tree-open-body';

      document.addEventListener('click', e => {
        if (
          !e.target.closest('[data-filter-desktop-button]') &&
          !e.target.closest('[data-filter-tree-desktop]')
        ) {
          document.body.classList.remove(
            `${filterTreeOpenBody}`,
            `boost-pfs-body-no-scroll`,
          );
          document.documentElement.classList.remove(
            `${filterTreeOpenBody}`,
            `boost-pfs-body-no-scroll`,
          );
        }
      });
    }

    // this.selectedFilters = this.filterTreeDesktop.querySelector(
    //   '.boost-pfs-filter-refine-by-wrapper',
    // );
    // this.desktopToolbar.insertAdjacentElement('afterend', this.selectedFilters);
  }

  _initMobilePopover() {
    this.mobileSortWrapper = this.el.querySelector(selectors.mobileSortWrapper);
    this.mobileSortOptions = this.mobileSortWrapper.querySelector(
      selectors.mobileSortOptions,
    );
    this.mobileSortButton = this.el.querySelector(selectors.mobileSortButton);
    this.mobileSortButton.addEventListener('click', () => {
      if (this.isMobile && this.popoverOverlay.classList.contains('hidden')) {
        this._showPopover();
      } else {
        this._hidePopover();
      }
    });
    this.mobileSortOptions.addEventListener('click', e => {
      setTimeout(() => {
        this._hidePopover();
      }, 100);
    });
    // append li to ul
    const titleDiv = document.createElement('li');
    titleDiv.classList.add('boost-pfs-filter-top-sorting-title');
    let divContent = `<a
    role="button"
    class="cursor-pointer w-4 h-4 flex items-center justify-center"><svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg"><path d="M15 0L1 14m14 0L1 0" stroke="currentColor" fill="none" fill-rule="evenodd"></path></svg></a>`;
    divContent += `<span class="flex-1 uppercase type-body-2 font-bold py-4">${this.mobileSortButton.getAttribute(
      'aria-label',
    )} </span>`;
    divContent += `<span class="w-4 h-4 py-4"></span>`;
    titleDiv.innerHTML = divContent;
    this.mobileSortOptions.prepend(titleDiv);
  }

  _showPopover() {
    document.body.classList.add('overflow-hidden');
    this.popoverOverlay.classList.remove('hidden');
  }

  _hidePopover() {
    document.body.classList.remove('overflow-hidden');
    this.popoverOverlay.classList.add('hidden');
  }
}
