/* eslint-disable sort-imports */
import DynamicFeaturedCollection from '../scripts/sections/DynamicFeaturedCollection';
import DynamicFeaturedProduct from '../scripts/sections/DynamicFeaturedProduct';
import DynamicMediaWithText from '../scripts/sections/DynamicMediaWithText';
// Custom Elements
import ExpandingList from '../scripts/components/ExpandingList';
// Sections
import MainAddresses from '../scripts/sections/MainAddresses';
import MainBoostCollection from '../scripts/sections/MainBoostCollection';
import MainCollection from '../scripts/sections/MainCollection';
import MainCollectionBanner from '../scripts/sections/MainCollectionBanner';
import MainFaq from '../scripts/sections/MainFaq';
import MainHeader from '../scripts/sections/MainHeader';
import MainMoreFromCollection from '../scripts/sections/MainMoreFromCollection';
import MainProduct from '../scripts/sections/MainProduct';
import MainProductRecommendations from '../scripts/sections/MainProductRecommendations';
import MainSearch from '../scripts/sections/MainSearch';
import ScrollToTop from '../scripts/sections/ScrollToTop';
import Sections from '@pixelunion/shopify-sections-manager';
// React
import mountPreact from '../scripts/preact/mountPreact';

// Init global sections
const sections = new Sections();
sections.register('main-collection', section => new MainCollection(section));
sections.register('main-product', section => new MainProduct(section));
sections.register('main-addresses', section => new MainAddresses(section));
sections.register(
  'main-collection-banner',
  section => new MainCollectionBanner(section),
);
sections.register(
  'main-collection-grid',
  section => new MainCollection(section),
);
sections.register(
  'main-boost-collection',
  section => new MainBoostCollection(section),
);
sections.register('main-header', section => new MainHeader(section));
sections.register('main-faq', section => new MainFaq(section));

sections.register(
  'dynamic-featured-collection',
  section => new DynamicFeaturedCollection(section),
  { lazy: true },
);
sections.register(
  'dynamic-featured-product',
  section => new DynamicFeaturedProduct(section),
  {
    lazy: true,
  },
);
sections.register(
  'dynamic-media-w-text',
  section => new DynamicMediaWithText(section),
  {
    lazy: true,
  },
);

sections.register(
  'main-product-recommendations',
  section => new MainProductRecommendations(section),
  {
    lazy: true,
  },
);

sections.register(
  'main-more-from-collection',
  section => new MainMoreFromCollection(section),
);

sections.register('main-search', section => new MainSearch(section));

sections.register('scroll-to-top', section => new ScrollToTop(section));

// Init preact
mountPreact();

// Custom elements
customElements.define('expanding-list', ExpandingList, { extends: 'ul' });
