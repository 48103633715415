import Product from '../components/product/Product';

export default class DynamicFeaturedProduct {
  constructor(section) {
    this.section = section;
    const product = document.querySelector('[data-product]');

    new Product(product, {
      isFeaturedProduct: true,
    });
  }
}
