import Product from '../components/product/Product';

export default class MainMoreFromCollection {
  constructor(section) {
    const products = section.el.querySelectorAll('[data-product]');
    if (products.length === 0) return;

    products.forEach(product => {
      new Product(product, {
        isFeaturedProduct: false,
        inlineImages: true,
        isMoreFromCollection: true,
      });
    });
  }
}
