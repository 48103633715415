import cn from 'classnames';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import { useEffect, useState } from 'preact/hooks';

const QuantitySelector = ({ item, setError, page = false }) => {
  const { updateLineItem } = useCartContext();
  const [quantity, setQuantity] = useState(item.quantity);
  const [updatingQuantity, setUpdatingQuantity] = useState(null);
  const [isMonogrammed, setIsMonogrammed] = useState(false);

  useEffect(() => {
    if (item.quantity < updatingQuantity) {
      setError(item.quantity);
    }
    setQuantity(item.quantity);
    setUpdatingQuantity(null);

    // check to see if any of the keys of items.properties contains the word monogram
    // if so, set isMonogrammed to true
    if (item.properties) {
      const keys = Object.keys(item.properties);
      const monogramKey = keys.find(key => key.includes('MONOGRAM TYPE'));
      if (monogramKey && !page) {
        setIsMonogrammed(true);
      } else {
        setIsMonogrammed(false);
      }
    }
  }, [item]);

  const updateQuantity = value => {
    setUpdatingQuantity(value);
    updateLineItem(item.key, value);
  };

  const Button = ({ children, label, onClick, disabled }) => (
    <button
      class={cn('h-8 w-6 focus:outline-none focus:ring-1', {
        'cursor-default text-primary-1': disabled,
      })}
      type="button"
      aria-label={label}
      onClick={onClick}
      disabled={disabled || updatingQuantity !== null || isMonogrammed}
    >
      {children}
    </button>
  );

  return (
    <div class="mb-2 inline-flex border border-primary-1 md:mb-0">
      <Button
        label={getTranslation('general.form.decrement')}
        onClick={() => updateQuantity(quantity - 1)}
        disabled={quantity === 1}
      >
        -
      </Button>

      <input
        class="type-subtitle-2 hide-arrows type-body-2 inline-block h-8 w-10 rounded-none border-x-0 border-y-0 px-1 text-center"
        type="number"
        name="quantity"
        value={quantity}
        aria-label={getTranslation('general.form.quantity')}
        min="1"
        onInput={e => updateQuantity(e.target.value)}
      />

      <Button
        label={getTranslation('general.form.increment')}
        onClick={() => updateQuantity(quantity + 1)}
      >
        +
      </Button>
    </div>
  );
};

export default QuantitySelector;
