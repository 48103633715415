import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import { getIdfromGid, getTranslation } from '../helpers/utilities';
import { useEffect, useState } from 'preact/hooks';

const CartUpsellProduct = ({ product }) => {
  if (!product) {
    return null;
  }

  const { addLineItem } = useCartContext();
  const [selectedVariant, setSelectedVariant] = useState(null);

  const upsell_image = product.images[0] ? product.images[0].originalSrc : '';
  const sizes = product.options.find(option => option.name === 'Size')
    ? product.options.find(option => option.name === 'Size').values
    : [];

  const validOptions = product.variants.filter(
    variant => variant.selectedOptions[0].value == product.primary_color,
  );

  useEffect(() => {
    if (validOptions.length > 0) {
      setSelectedVariant(validOptions[0]);
    }
  }, []);

  const price = validOptions[0].priceV2.amount;

  const handleOptionChange = option => {
    const variantSelected = validOptions.find(
      variant => variant.selectedOptions[1].value == option,
    );
    setSelectedVariant(variantSelected);
  };
  if (!selectedVariant || !selectedVariant.id) {
    return null;
  }

  const handleSubmit = event => {
    event.preventDefault();
    addLineItem(event);
  };

  return (
    <div
      class="bg-surf mr-2 flex w-72 shrink-0 origin-center snap-start overflow-hidden md:mr-4 md:w-96"
      data-product-id={product.id}
    >
      <div class="h-auto w-20 md:w-28">
        <img src={upsell_image} class="h-full w-full object-cover" />
      </div>
      <div class="h-full flex-1 p-3 align-middle md:p-4">
        <h4 class="type-heading-7">{product.title}</h4>
        <div class="type-small text-stone mt-2 mb-2.5 flex flex-row items-center">
          <span>{price}</span>
          <span class="px-2">·</span>
          <span>{product.primary_color}</span>
        </div>
        <div class="flex items-center">
          <form method="post" action="/cart/add" onSubmit={handleSubmit}>
            <input type="hidden" name="form_type" value="product" />
            <input type="hidden" name="utf8" value="✓" />
            <input
              type="hidden"
              name="options[Color]"
              value={product.primary_color}
            />
            <input
              type="hidden"
              name="id"
              value={getIdfromGid(selectedVariant.id)}
            />
            <input type="hidden" name="quantity" value="1" />

            {sizes.length > 0 && (
              <select
                name="options[Size]"
                class="size-dropdown type-small h-8 w-15 appearance-none rounded-full border-none pt-0 pb-0 md:w-20"
                onChange={e => handleOptionChange(e.target.value)}
              >
                {sizes.map((value, index) => {
                  return (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            )}

            <button
              class="button button--small button--primary md:ml-4"
              disabled={!selectedVariant.availableForSale}
            >
              {selectedVariant.availableForSale
                ? getTranslation('mini_cart.add_to_cart')
                : getTranslation('mini_cart.sold_out')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CartUpsellProduct;
