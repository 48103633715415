const shopifyAccessToken = import.meta.env.VITE_SHOPIFY_ACCESS_TOKEN;
const shopifyShopUrl = import.meta.env.VITE_SHOPIFY_SHOP_URL;
const shopifyUrl = `https://${shopifyShopUrl}/api/2022-04/graphql.json`;

const makeShopifyRequest = params => {
  const reqOptions = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Shopify-Storefront-Access-Token': shopifyAccessToken,
    },
    body: JSON.stringify(params),
  };

  return fetch(shopifyUrl, reqOptions)
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      return null;
    })
    .then(response => {
      return response;
    });
};

const fetchProduct = handle => {
  const params = {
    query: `query($handle: String!) {
      product(handle: $handle) {
        ...on Product {
            id
            availableForSale
            title
            handle
            tags
            updatedAt
            description
            vendor
            productType
            options {
              id
              name
              values
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            images(first: 1) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                node {
                  originalSrc
                }
              }
            }
            variants(first: 100) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                node {
                  title
                  sku
                  id
                  availableForSale
                  selectedOptions {
                    name
                    value
                  }
                  compareAtPriceV2 {
                    amount
                  }
                  priceV2 {
                    amount
                  }
                }
              }
            }
        }
      }
    }`,
    variables: { handle },
  };

  return makeShopifyRequest(params);
};

const fetchProductsByID = productIds => {
  const params = {
    query: `query($ids: [ID!]!) {
      nodes(ids: $ids) {
        ...on Product {
            id
            title

        }
      }
    }`,
    variables: {
      ids: productIds,
    },
  };

  return makeShopifyRequest(params);
};

const fetchProductsByHandle = handles => {
  let query = '(NOT tag:subscription)';

  const handlesString = handles.join(` OR `);
  query = `(handle:${handlesString})`;

  const params = {
    query: `query {
      products(first: 100, query:"${query}") {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            title
            handle
            updatedAt
            productType
            vendor
            tags
            description
            options {
              name
              values
            }
          }
        }
      }
    }`,
  };

  return makeShopifyRequest(params);
};

export { fetchProduct, fetchProductsByID, fetchProductsByHandle };
